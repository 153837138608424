import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "test"
};
import statistic from './detail/cpns/statistic.vue';
import LoginHintDialog from './detail/cpns/login-hint-dialog.vue';
import { ref } from 'vue';
export default {
  __name: 'test',

  setup(__props) {
    const loginHintDialogRef = ref(null);

    const handleToLogin = () => {
      loginHintDialogRef.value.dialogVisible = true;
    };

    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 8
        }, {
          default: _withCtx(() => [_createVNode(statistic, {
            title: "更新日期",
            value: "2023-12-25"
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8
        }, {
          default: _withCtx(() => [_createVNode(statistic, {
            title: "总下载量",
            value: "3000"
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 8
        })]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: handleToLogin
      }, {
        default: _withCtx(() => [_createTextVNode("前往下载")]),
        _: 1
      }), _createVNode(LoginHintDialog, {
        ref_key: "loginHintDialogRef",
        ref: loginHintDialogRef
      }, null, 512)]);
    };
  }

};